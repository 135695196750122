<template>
  <div class="event-participants-register register">
    <div class="register__header">
      <ui-select
          :label="$t('role')"
          v-model="role"
          :options="getRoleOptions"
          @input="updateEventUsersList"
      />
    </div>
    <div class="register__main">
      <ui-input search v-model="search" @input="updateEventUsersList" :placeholder="$t('search')"/>
      <div class="register__main-cards">
        <ui-loader v-model="visibleLoader"/>
        <event-participants-register-card v-for="(item, key) in getEventUsers" @checked="checkUsers($event)" :data="item" :key="key" v-show="!visibleLoader"/>
        <div class="register__main-cards-bottom" v-if="!visibleLoader">
          <div class="show-all-cards" @click="loadMore" v-if="visibleLoadMore">
            <img src="@/assets/svg/arrows/more.svg" alt="">
            <p>{{ $t('show-more') }}</p>
          </div>
          <div class="show-all-cards" @click="callEventUsersApi" v-if="getEventUsers.length > 10 && !visibleLoader">
            <img src="@/assets/svg/arrows/more.svg" alt="" style="transform: rotate(180deg)">
            <p>{{ $t('collapse') }}</p>
          </div>
        </div>
      </div>
      <ui-button :disabled="!checkedUsers.length" @click="sendCheckedUsers">{{ $t('register') }}</ui-button>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import UiInput from "@/components/ui/UiInput.vue";
import EventParticipantsRegisterCard
  from "@/components/template/event/event-participants/EventParticipantsRegisterCard.vue";
import UiButton from "@/components/ui/UiButton.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  name: "EventParticipantsCard",
  components: {UiLoader, UiButton, EventParticipantsRegisterCard, UiInput, UiSelect},
  data () {
    return {
      getRoleOptions: [
        { name: this.$t('inspector'), value: 'Inspector' },
        { name: this.$t('business'), value: 'Company' },
        { name: this.$t('public-authority'), value: 'Government' },
        { name: this.$t('eco-lawyer'), value: 'Lawyer' },
        { name: this.$t('eco-activist'), value: 'Active'}
      ],
      role: '',
      search: '',
      visibleLoadMore: true,
      visibleLoader: true,
      checkedUsers: []
    }
  },

  computed: {
    ...mapGetters(['getEventUsers'])
  },

  methods: {
    ...mapActions(['eventUsers', 'registerUsersEvent']),
    ...mapMutations(['setEventUsers']),

    callEventUsersApi() {
      this.eventUsers({skip: 0, take: 10, roles: [this.role.value], search: this.search.length ? this.search : null}).then((res) => {
        this.visibleLoader = false
        this.setEventUsers(res.data)
        if (res.meta.total === res.data.length) {
          this.visibleLoadMore = false
        }else {
          this.visibleLoadMore = true
        }
      })
    },

    loadMore() {
      this.eventUsers({skip: this.getEventUsers.length, take: 10, roles: [this.role.value], search: this.search.length ? this.search : null}).then((res) => {
        this.setEventUsers(this.$store.state.eventsStore.eventUsers.concat(res.data))
        if (res.meta.total === this.getEventUsers.length) {
          this.visibleLoadMore = false
        }else {
          this.visibleLoadMore = true
        }
      })
    },

    updateEventUsersList() {
      this.visibleLoader = true
      this.eventUsers({skip: 0, take: 10, roles: [this.role.value], search: this.search.length ? this.search : null}).then((res) => {
        this.setEventUsers(res.data)
        this.visibleLoader = false
        if (res.meta.total === this.getEventUsers.length) {
          this.visibleLoadMore = false
        }else {
          this.visibleLoadMore = true
        }
      })
    },

    checkUsers (data) {
      if (data.val) {
        this.checkedUsers.push(+data.userId)
      } else {
        this.checkedUsers = this.checkedUsers.filter((val) => +val !== +data.userId)
      }
      console.log(this.checkedUsers)
    },

    sendCheckedUsers () {
      this.registerUsersEvent({id: this.$route.params.id, users: this.checkedUsers}).then(() => {
        this.$router.push(`/event-participants/${this.$route.params.id}`)
      })
    }
  },

  mounted () {
    this.callEventUsersApi()
  }
}
</script>

<style lang="scss" scoped>
.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  max-width: 740px;
  width: 100%;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 434px;
    width: 100%;
    padding: 30px 34px;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 2px 2px 10px 0px rgba(153, 153, 153, 0.20);
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 34px;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 2px 2px 10px 0px rgba(153, 153, 153, 0.20);
    max-width: 434px;
    width: 100%;

    &-cards {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 10px 0 20px 0;
      border-bottom: 1px solid #F5F5F5;
      height: 300px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 7px;
        height: 4px;
        background: #E0E0E0FF;
      }

      &::-webkit-scrollbar-thumb {
        background: #9A9A9A;
        border-radius: 10px;
      }

      &-bottom {
        display: flex;
        align-items: center;
        gap: 10px;

        .show-all-cards {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #9A9A9A;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
