<template>
  <div class="event-participants-register__card card">
    <ui-checkbox v-model="checkBox" @input="$emit('checked', {val: checkBox, userId: data.id})"/>
    <div class="card__right" @click="$router.push('/user/' + data?.id)">
      <img :src="getUserAvatar(data.avatarSrc.fileSrc)" alt="">
      <div class="card__right-info">
        <p class="card__right-info-nickname">{{ getNickname }}</p>
        <p class="card__right-info-role"><img :src="getUserRole(data.role).icon" alt="">{{ $t(getUserRole(data.role).text) }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import {defineComponent} from "vue";
import UiCheckbox from "@/components/ui/UiCheckbox.vue";
import {getUserAvatar, getUserRole} from "@/utils/user";

export default defineComponent({
  components: {UiCheckbox},

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return{
      checkBox: null
    }
  },

  methods: {
    getUserAvatar,
    getUserRole,
    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.'
      }
    },
  },

  computed: {
    getNickname() {
      return this.data.role === 'Inspector' || this.data.role === 'Lawyer' ? this.getUserName(this.data.lastName, this.data.firstName, this.data.middleName) : '' ||
      this.data.role === 'Company' ? this.data.companyName : '' ||
      this.data.role === 'Government' ? this.data.companyName : this.data.role === 'Active' ? this.data.login : ''
    },
  }
})
</script>

<style lang="scss" scoped>
.card {
  border-radius: 200px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px 0px rgba(153, 153, 153, 0.20);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0 5px 20px;
  max-width: 366px;
  width: 100%;
  cursor: pointer;

  &__right {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 1px solid #CE2121;
    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-nickname {
        color: #1B1A1F;
        font-size: 16px;
      }

      &-role {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #9A9A9A;
        font-size: 12px;

        img {
          width: 8px;
          height: 8px;
          border: none;
          border-radius: inherit;
        }
      }
    }
  }
}
</style>
